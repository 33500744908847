<template>
  <footer class="flex flex-col w-full">
    <div class="container w-fit mx-auto mb-48">
      <heading-component>Legyél a partnerünk</heading-component>
      <p class="mt-4 mb-8">Bízza ránk a munkát és élvezze az eredményt megbízható szakemberekkel</p>
      <form-component/>
    </div>

    <div class="bg-blue-500 py-24 rounded-tl-[4rem] md:rounded-tl-[6rem]">
      <div class="container flex flex-col md:flex-row text-white mx-auto px-4">

        <div class="md:w-1/2 flex mb-8 md:mb-0">
          <div class="w-3/4 my-auto">
            <logo-component color="white"/>
          </div>
        </div>

        <div class="md:w-1/2">
          <div class="mb-8">
            <a class="font-bold underline" href="mailto:info@mgmdjob.hu">info@mgmdjob.hu</a>
          </div>

          <div class="mb-8">
            <h4 class="font-bold">Irodánk</h4>
            <p>Budapest, Hungary</p>
            <p>
              XIII. ker. Visegrádi utca 47/b (Alfa Center irodaház)
            </p>
          </div>

          <p class="">
            MGMD Job Kft. © {{ currentYear }} – All right reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import LogoComponent from '@/components/UI/LogoComponent.vue'
import FormComponent from '@/components/FormComponent.vue'
import HeadingComponent from '@/components/UI/HeadingComponent.vue'

const currentYear = new Date().getFullYear()
</script>
