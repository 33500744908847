<template>

  <svg v-if="format === 'mgmdjob'" :fill="color" viewBox="0 0 386 71" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.4757 56.5159V17.8091L38.5727 56.5159H27.4717L11.5687 17.8091V56.5159H0V1.40387H15.7159L33.0222 43.119L50.3284 1.40387H65.982V56.5159H54.4757Z"
    />
    <path
      d="M106.987 0C111.186 0 115.084 0.467958 118.68 1.40387C122.297 2.31305 125.613 3.48963 128.628 4.93361V18.4108C127.443 17.5551 126.102 16.7261 124.605 15.9239C123.129 15.1217 121.518 14.4131 119.772 13.7981C118.025 13.183 116.155 12.6883 114.159 12.314C112.163 11.9396 110.064 11.7524 107.86 11.7524C105.116 11.7524 102.725 12.0065 100.688 12.5145C98.6508 13.0226 96.9046 13.7045 95.4494 14.5602C93.9942 15.4159 92.7989 16.4186 91.8634 17.5685C90.928 18.6916 90.19 19.8949 89.6495 21.1784C89.109 22.462 88.7348 23.7722 88.5269 25.1093C88.319 26.4463 88.2151 27.7432 88.2151 29C88.2151 30.0964 88.2982 31.2863 88.4646 32.5698C88.6516 33.8534 88.9843 35.1369 89.4624 36.4205C89.9613 37.704 90.6473 38.9341 91.5204 40.1107C92.3935 41.2872 93.5265 42.3301 94.9193 43.2393C96.3329 44.1485 98.0272 44.8838 100.002 45.4454C101.977 45.9802 104.326 46.2476 107.049 46.2476C108.421 46.2476 109.617 46.1941 110.635 46.0871C111.654 45.9802 112.579 45.8331 113.41 45.6459C114.263 45.4587 115.053 45.2314 115.78 44.964C116.529 44.6966 117.298 44.4159 118.088 44.1217V37.4633H105.116V25.39H129.657V53.5477C128.118 54.2162 126.393 54.8313 124.48 55.3928C122.589 55.9276 120.624 56.3822 118.587 56.7566C116.57 57.1577 114.543 57.4652 112.506 57.6791C110.469 57.893 108.546 58 106.737 58C103.931 58 101.239 57.7593 98.6612 57.278C96.0835 56.7967 93.672 56.0613 91.4269 55.0719C89.2025 54.0558 87.1653 52.7722 85.3151 51.2213C83.4858 49.6704 81.9162 47.8253 80.6066 45.686C79.3177 43.5468 78.3095 41.1 77.5819 38.3458C76.8751 35.5648 76.5217 32.4495 76.5217 29C76.5217 25.5772 76.8959 22.4753 77.6442 19.6943C78.3926 16.9133 79.432 14.4532 80.7625 12.314C82.1137 10.1747 83.7248 8.32965 85.5958 6.7787C87.4875 5.22775 89.5559 3.95758 91.8011 2.96819C94.067 1.95205 96.4784 1.20332 99.0354 0.721992C101.613 0.240664 104.264 0 106.987 0Z"
    />
    <path
      d="M196.605 56.5159V17.8091L180.702 56.5159H169.601L153.698 17.8091V56.5159H142.13V1.40387H157.845L175.152 43.119L192.458 1.40387H208.112V56.5159H196.605Z"
    />
    <path
      d="M274 28.9198C274 32.4495 273.699 35.5915 273.096 38.3458C272.493 41.0733 271.641 43.4532 270.539 45.4855C269.458 47.5178 268.148 49.2425 266.61 50.6598C265.092 52.077 263.398 53.2135 261.527 54.0692C259.656 54.9249 257.64 55.5533 255.478 55.9544C253.316 56.3287 251.06 56.5159 248.711 56.5159H221.52V1.40387H248.649C250.998 1.40387 253.253 1.60443 255.415 2.00553C257.577 2.3799 259.594 2.99493 261.465 3.85062C263.356 4.70632 265.071 5.84278 266.61 7.26003C268.148 8.65053 269.458 10.3753 270.539 12.4343C271.641 14.4666 272.493 16.8465 273.096 19.574C273.699 22.3015 274 25.4168 274 28.9198ZM262.338 28.9198C262.338 26.2725 262.068 23.9728 261.527 22.0207C261.007 20.0687 260.155 18.4643 258.97 17.2075C257.806 15.9239 256.278 14.9746 254.386 14.3596C252.495 13.7178 250.187 13.397 247.464 13.397H233.089V44.5228H247.464C250.187 44.5228 252.495 44.2153 254.386 43.6003C256.278 42.9585 257.806 41.9959 258.97 40.7123C260.155 39.402 261.007 37.7709 261.527 35.8188C262.068 33.8668 262.338 31.5671 262.338 28.9198Z"
    />
    <path
      d="M295.928 13V57.8C295.928 59.592 295.629 61.256 295.032 62.792C294.435 64.3707 293.603 65.736 292.536 66.888C291.469 68.04 290.232 68.936 288.824 69.576C287.416 70.2587 285.901 70.6 284.28 70.6V70.024C285.688 69.512 286.947 68.616 288.056 67.336C289.208 66.0987 290.104 64.6267 290.744 62.92C291.427 61.2133 291.768 59.4427 291.768 57.608V13H295.928ZM291.96 17.672H291.768C291.725 16.5627 291.299 15.624 290.488 14.856C289.72 14.0453 288.781 13.64 287.672 13.64C287.672 13.64 287.523 13.64 287.224 13.64C286.968 13.64 286.84 13.64 286.84 13.64V13H291.96V17.672ZM295.736 17.672V13H300.856V13.64C300.856 13.64 300.707 13.64 300.408 13.64C300.152 13.64 300.024 13.64 300.024 13.64C298.915 13.64 297.955 14.0453 297.144 14.856C296.376 15.624 295.971 16.5627 295.928 17.672H295.736Z"
    />
    <path
      d="M327.893 18.504C330.88 18.504 333.674 18.9947 336.277 19.976C338.922 20.9147 341.226 22.28 343.189 24.072C345.194 25.864 346.752 27.9973 347.861 30.472C348.97 32.904 349.525 35.6133 349.525 38.6C349.525 41.5867 348.97 44.3173 347.861 46.792C346.752 49.224 345.194 51.336 343.189 53.128C341.226 54.92 338.922 56.3067 336.277 57.288C333.674 58.2267 330.88 58.696 327.893 58.696C324.906 58.696 322.112 58.2267 319.509 57.288C316.906 56.3067 314.602 54.92 312.597 53.128C310.634 51.336 309.098 49.224 307.989 46.792C306.88 44.3173 306.325 41.5867 306.325 38.6C306.325 35.6133 306.88 32.904 307.989 30.472C309.098 27.9973 310.634 25.864 312.597 24.072C314.602 22.28 316.906 20.9147 319.509 19.976C322.112 18.9947 324.906 18.504 327.893 18.504ZM327.893 56.776C331.05 56.776 333.909 56.008 336.469 54.472C339.029 52.936 341.056 50.8027 342.549 48.072C344.042 45.3413 344.789 42.184 344.789 38.6C344.789 34.9733 344.042 31.816 342.549 29.128C341.056 26.3973 339.029 24.264 336.469 22.728C333.909 21.192 331.05 20.424 327.893 20.424C324.778 20.424 321.941 21.192 319.381 22.728C316.821 24.264 314.794 26.3973 313.301 29.128C311.808 31.816 311.061 34.9733 311.061 38.6C311.061 42.184 311.808 45.3413 313.301 48.072C314.794 50.8027 316.821 52.936 319.381 54.472C321.941 56.008 324.778 56.776 327.893 56.776Z"
    />
    <path
      d="M360.71 19.4H368.326C369.478 19.4 370.801 19.5067 372.294 19.72C373.83 19.9333 375.302 20.36 376.71 21C378.118 21.64 379.291 22.6 380.23 23.88C381.169 25.16 381.638 26.8667 381.638 29C381.638 30.28 381.297 31.4747 380.614 32.584C379.931 33.6933 379.057 34.632 377.99 35.4C376.923 36.1253 375.793 36.6373 374.598 36.936C376.689 37.1493 378.523 37.7467 380.102 38.728C381.723 39.6667 383.003 40.8613 383.942 42.312C384.881 43.7627 385.35 45.3413 385.35 47.048C385.35 49.1387 384.902 50.888 384.006 52.296C383.153 53.6613 382.022 54.7493 380.614 55.56C379.206 56.3707 377.67 56.9467 376.006 57.288C374.342 57.6293 372.721 57.8 371.142 57.8H363.334C363.334 57.8 363.334 57.6507 363.334 57.352C363.377 57.0107 363.398 56.6907 363.398 56.392C363.398 56.0507 363.398 55.88 363.398 55.88H371.078C372.913 55.88 374.555 55.5813 376.006 54.984C377.499 54.344 378.673 53.3627 379.526 52.04C380.379 50.7173 380.806 49.0533 380.806 47.048C380.806 45 380.358 43.3573 379.462 42.12C378.566 40.84 377.393 39.88 375.942 39.24C374.491 38.5573 372.891 38.1093 371.142 37.896C369.393 37.64 367.643 37.512 365.894 37.512V36.872C367.046 36.872 368.283 36.7867 369.606 36.616C370.929 36.4453 372.145 36.104 373.254 35.592C374.406 35.0373 375.345 34.248 376.07 33.224C376.795 32.1573 377.158 30.7493 377.158 29C377.158 26.312 376.369 24.3707 374.79 23.176C373.211 21.9387 371.035 21.32 368.262 21.32H361.286C361.286 21.2773 361.222 21.1067 361.094 20.808C361.009 20.4667 360.923 20.1467 360.838 19.848C360.753 19.5493 360.71 19.4 360.71 19.4ZM363.91 19.4V57.8H359.878V19.4H363.91ZM360.07 53.704V57.8H355.27V57.16C355.27 57.16 355.398 57.16 355.654 57.16C355.91 57.16 356.038 57.16 356.038 57.16C357.147 57.16 358.043 56.8187 358.726 56.136C359.451 55.4533 359.835 54.6427 359.878 53.704H360.07ZM360.07 23.496H359.878C359.835 22.472 359.451 21.64 358.726 21C358.043 20.36 357.147 20.04 356.038 20.04C356.038 20.04 355.91 20.04 355.654 20.04C355.398 20.04 355.27 20.04 355.27 20.04V19.4H360.07V23.496Z"
    />
  </svg>

  <svg v-else-if="format === 'MGMD'" :fill="color" viewBox="0 0 838 704" xmlns="http://www.w3.org/2000/svg">

    <path
      d="M346.178 279.183V87.9752L245.118 279.183H174.575L73.5157 87.9752V279.183H0V6.93498H99.8704L209.847 213.003L319.823 6.93498H419.297V279.183H346.178Z"
    />
    <path
      d="M679.872 0C706.557 0 731.326 2.31166 754.18 6.93498C777.166 11.4262 798.237 17.2384 817.392 24.3715V90.9474C809.862 86.7203 801.341 82.6254 791.83 78.6625C782.45 74.6997 772.212 71.1992 761.116 68.161C750.019 65.1228 738.13 62.679 725.448 60.8297C712.766 58.9804 699.423 58.0557 685.42 58.0557C667.983 58.0557 652.791 59.3106 639.844 61.8204C626.898 64.3302 615.802 67.6987 606.554 71.9257C597.307 76.1527 589.711 81.1063 583.766 86.7864C577.822 92.3344 573.132 98.2786 569.697 104.619C566.263 110.96 563.885 117.432 562.564 124.037C561.243 130.642 560.582 137.048 560.582 143.257C560.582 148.673 561.111 154.551 562.167 160.892C563.356 167.232 565.47 173.573 568.508 179.913C571.679 186.254 576.038 192.33 581.587 198.142C587.135 203.955 594.335 209.106 603.186 213.598C612.169 218.089 622.935 221.721 635.485 224.495C648.035 227.137 662.963 228.458 680.268 228.458C688.987 228.458 696.583 228.194 703.056 227.666C709.529 227.137 715.408 226.411 720.692 225.486C726.108 224.561 731.128 223.439 735.752 222.118C740.507 220.797 745.395 219.41 750.415 217.957V185.065H667.983V125.424H823.931V264.52C814.155 267.822 803.191 270.861 791.037 273.635C779.016 276.277 766.532 278.522 753.586 280.372C740.772 282.353 727.892 283.872 714.945 284.929C701.999 285.986 689.78 286.514 678.287 286.514C660.453 286.514 643.345 285.325 626.964 282.947C610.583 280.57 595.259 276.937 580.992 272.05C566.857 267.03 553.911 260.689 542.154 253.028C530.529 245.366 520.555 236.252 512.232 225.684C504.042 215.117 497.635 203.03 493.011 189.424C488.52 175.686 486.274 160.297 486.274 143.257C486.274 126.349 488.652 111.026 493.407 97.2879C498.163 83.55 504.768 71.3973 513.223 60.8297C521.81 50.2621 532.048 41.1476 543.937 33.4861C555.959 25.8246 569.103 19.5501 583.37 14.6625C597.769 9.64293 613.093 5.94427 629.342 3.56656C645.723 1.18885 662.566 0 679.872 0Z"
    />
    <path
      d="M346.178 704V512.793L245.118 704H174.575L73.5157 512.793V704H0V431.752H99.8704L209.847 637.82L319.823 431.752H419.297V704H346.178Z"
    />
    <path
      d="M838 567.678C838 585.115 836.085 600.636 832.254 614.241C828.422 627.715 823.006 639.472 816.005 649.511C809.135 659.55 800.813 668.07 791.037 675.071C781.394 682.072 770.627 687.686 758.738 691.913C746.848 696.14 734.034 699.245 720.296 701.226C706.557 703.075 692.224 704 677.296 704H504.504V431.752H676.9C691.827 431.752 706.16 432.743 719.899 434.724C733.638 436.574 746.452 439.612 758.341 443.839C770.363 448.066 781.261 453.68 791.037 460.681C800.813 467.55 809.135 476.07 816.005 486.241C823.006 496.281 828.422 508.037 832.254 521.511C836.085 534.985 838 550.374 838 567.678ZM763.89 567.678C763.89 554.601 762.172 543.24 758.738 533.598C755.435 523.955 750.019 516.029 742.489 509.82C735.091 503.48 725.382 498.79 713.36 495.752C701.339 492.582 686.675 490.997 669.37 490.997H578.02V644.755H669.37C686.675 644.755 701.339 643.236 713.36 640.198C725.382 637.028 735.091 632.272 742.489 625.932C750.019 619.459 755.435 611.401 758.738 601.758C762.172 592.116 763.89 580.755 763.89 567.678Z"
    />
  </svg>

</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3B82F6'
    },
    format: {
      type: String,
      default: 'mgmdjob'
    }
  }
}
</script>
